<nz-table
  #paymentTbl
  [nzData]="studentPaymentDetails"
  [nzLoading]="loading"
  [nzFrontPagination]="false"
  nzShowSizeChanger
  [nzScroll]="{ x: '900px' }"
>
  <thead>
    <tr>
      <th>Order Id</th>
      <th>Course</th>
      <th>Payment For</th>
      <th>Amount</th>
      <th>Start Date</th>
      <th>End Date</th>
      <th>Created On</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of paymentTbl.data; index as i">
      <td>{{ data.orderId }}</td>
      <td>{{ data.course?.name }}</td>
      <td>
        {{
          data.paymentForPracticeTest
            ? "Practice Test"
            : data.paymentForPracticeExercise
            ? "Practice Exercise"
            : data.paymentForSeriesTest
            ? "Series Test"
            : data.paymentForWatchAndLearn
            ? "Watch And Learn"
            : ""
        }}
      </td>
      <td>{{ data.totalAmount }}.00</td>
      <td>{{ data.startDate | date: "mediumDate" }}</td>
      <td>{{ data.endDate | date: "mediumDate" }}</td>
      <td>{{ data.createdAt | date: "mediumDate" }}</td>
      <td>
        <span class="{{ data.success ? 'active' : 'inactive' }} status"></span>
      </td>
    </tr>
  </tbody>
</nz-table>
