import { Component, Input, OnInit } from '@angular/core';
import { HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-payment-details-by-student',
  templateUrl: './payment-details-by-student.component.html',
  styleUrls: ['./payment-details-by-student.component.scss'],
})
export class PaymentDetailsByStudentComponent implements OnInit {
  @Input() student = '';
  studentPaymentDetails: any[] = [];
  loading = false;
  constructor(
    private httpRequestService: HttpRequestService

  ) {}

  // on page load functions
  ngOnInit(
  ): void {
    this.getPaymentDetailsByStudent();
  }

   /* Get all payments */
   getPaymentDetailsByStudent(skip = 0): void {
    const params: any = {};
    params.student = this.student;
    this.loading = true;
    this.httpRequestService.request('get', 'payments', params).subscribe(
      (result) => {
        this.loading = false;
        this.studentPaymentDetails = result.data;
      },
      (err) => {
        this.loading = false;
      }
    );
  }
}
